import React, { useState, useEffect } from 'react';
import { CheckOutlined, RightOutlined } from '@ant-design/icons';
import { Col, Row, Form, Button, Space, Switch, Typography, Input, InputNumber, Table, Divider, theme, Alert } from 'antd';
import { ContactInfoData, UsersData } from '../AppState';

const Users = ({ prev, next, apiConnection }) => {
    const { token } = theme.useToken();
    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [secondaryAccounts, setSecondaryAccounts] = useState(0); // Tracks the number of secondary accounts
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [isChecked, setIsChecked] = useState(false); // Tracks email confirmation checkbox
    const [isConfirmAccountsChecked, setIsConfirmAccountsChecked] = useState(false); // Tracks secondary accounts confirmation checkbox
    const [errorMessage, setErrorMessage] = useState(''); // Tracks error messages

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
        },
        {
            title: 'Role',
            dataIndex: 'role',
        }
    ];

    const onSelectChange = (newSelectedRowKeys, selectedRows) => {
        setSelectedRowKeys(newSelectedRowKeys);
        setSelectedRows(selectedRows);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                let selectData = await apiConnection.getUsers();
                setData(selectData);
                setLoading(false);

                if (selectData.length === 0) {
                    setSecondaryAccounts(0); // No additional accounts
                    setIsConfirmAccountsChecked(true); // Automatically confirm accounts
                }

            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [apiConnection]);

    const validateSelectedRows = () => {
        if (secondaryAccounts > 0 && selectedRows.length !== secondaryAccounts) {
            setErrorMessage(
                `You selected ${secondaryAccounts} Additional Account${secondaryAccounts > 1 ? 's' : ''}, please label which user${secondaryAccounts > 1 ? 's' : ''} has the additional Homebot account${secondaryAccounts > 1 ? 's' : ''}.`
            );
            return false;
        }
        setErrorMessage('');
        return true;
    };

    const onFinish = async (values) => {
        if (!validateSelectedRows()) return; // Prevent submission if validation fails
        ContactInfoData.value['email'].value = values['email'];
        if (selectedRows.length <= secondaryAccounts) {
            UsersData.value = selectedRows;
        }
        next();
    };

    // Handle email confirmation checkbox change
    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked); // Update isChecked state based on the checkbox
    };

    // Handle the new "Confirm # of accounts" checkbox change
    const handleConfirmAccountsChange = (e) => {
        setIsConfirmAccountsChecked(e.target.checked);
    };

    return (
        <Form
            layout="vertical"
            onFinish={onFinish}
        >
            <Row gutter={24}>
                <Col span={16} offset={4}>
                    <Typography.Title level={3} style={{ textAlign: 'center', marginTop: '5px', marginBottom: '40px', color: token.colorLink }}>
                        Confirm Homebot Account
                    </Typography.Title>
                    
                </Col>
            </Row>
            {/*Old Header Description - <Typography.Text style={{ textAlign: 'center', fontSize: '14px', fontWeight: 'italic', marginBottom: '20px', display: 'block' }}>
                        Within The Same CRM Account, You Can Connect Multiple User Homebot Accounts<br /> Let's Confirm Your Primary Homebot Account and Set Up Any Additional User Homebot Accounts to use with Your CRM.
                    </Typography.Text>
                    <br /> */}

            {/* Primary email confirmation */}
            <Row gutter={24}>
                <Col sm={{ span: 10, offset: 4 }} span={16} offset={4}>
                    <Form.Item
                        label={
                            <span style={{ fontStyle: 'bold', fontWeight: 'bold', fontSize: '16px', marginBottom: '5px', display: 'block' }}>
                                Confirm Account Email
                            </span>
                        }
                        name="email"
                        rules={[{ required: true, type: 'email', message: 'Primary Homebot Account Email Required' }]}
                        initialValue={ContactInfoData.value['email'].value}
                        style={{ marginBottom: '10px' }}  // Reduce margin below the email input box
                    >
                        <Input />
                    </Form.Item>
                </Col>

                {/* Checkbox with offset */}
                <Col sm={{ span: 12, offset: 4 }} span={16} offset={4} style={{ marginTop: '15px', marginBottom: '40px' }}>
                    <Space direction="horizontal" size="middle">

                        <Switch
                            checkedChildren={<CheckOutlined style={{ color: 'white', fontSize: '14px' }} />}
                            unCheckedChildren={<RightOutlined style={{ color: 'blue', fontSize: '14px' }} />}
                            defaultChecked={false}
                            style={{
                                border: '1px solid gray',
                                boxShadow: '0px 0px 1px white',
                                borderRadius: '7px',
                                padding: '0px',
                            }}
                            onChange={(checked) => handleCheckboxChange({ target: { checked } })}
                        />
                        <Typography.Text strong>Confirm Email is Correct</Typography.Text>
                    </Space>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span={16} offset={4}>
                    <Divider style={{ backgroundColor: 'white', height: '1px', marginTop: '2px', marginBottom: '30px' }} />
                </Col>
            </Row>

            {/* Conditionally render additional content based on isChecked */}
            {/* <Typography.Text style={{ textAlign: 'center', marginTop: '10px', fontSize: '16px', fontWeight: 'bold', display: 'block' }}>You may choose to set up more secondary Homebot Accounts in addition to the primary account, within the same CRM. For example, each agent within your CRM could have their own Homebot Account. </Typography.Text> */}
            {isChecked && data.length > 0 && (
                <>
                    <Row gutter={24}>
                        <Col span={13} offset={6}>
                            <Typography.Title level={4} style={{ textAlign: 'center', marginTop: '5px', marginBottom: '10px', color: token.colorLink }}>
                                Add Any Additional Homebot Accounts
                            </Typography.Title>
                            <Typography.Text style={{ textAlign: 'center', fontSize: '14px', fontWeight: 'italic', marginBottom: '20px', display: 'block' }}>
                                You can add & connect multiple Homebot accounts for the agent/users within this same CRM.
                            </Typography.Text>
                            <br />
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={12} offset={4}>
                            <Typography.Text
                                style={{ fontStyle: 'normal', fontSize: 'medium', fontWeight: 'normal', marginTop: '5px', marginBottom: '5px', display: 'block' }}>
                                # of additional Homebot Accounts to Set Up <br />
                                <InputNumber min={0} defaultValue={secondaryAccounts} onChange={setSecondaryAccounts} />
                            </Typography.Text>
                            <Typography.Text
                                style={{ fontStyle: 'italic', fontSize: 'smaller', fontWeight: 'lighter', marginTop: '10px', marginBottom: '20px', display: 'block' }}>
                                No Additional Accounts? Enter 0. Agents within your CRM have their own separate Homebot Account? Enter 1 for each additional account.
                            </Typography.Text>
                        </Col>
                    </Row>

                    {/* Conditionally render the table if there are secondary accounts */}
                    {secondaryAccounts > 0 && (
                        <>
                            <Row gutter={24}>
                                <Col span={16} offset={4}>
                                    <Table
                                        size='small'
                                        pagination={false}
                                        loading={isLoading}
                                        rowSelection={rowSelection}
                                        columns={columns}
                                        dataSource={data}
                                        scroll={{ y: 200 }}
                                    />
                                    <br />
                                </Col>
                            </Row>

                            {/* Render Confirm # of accounts checkbox AFTER table if secondaryAccounts > 0 */}
                            <Row gutter={24}>
                                <Col span={16} offset={4} style={{ marginTop: '15px', marginBottom: '50px' }}>
                                    <Space direction="horizontal" size="middle">
                                        <Switch
                                            checkedChildren={<CheckOutlined style={{ color: 'white', fontSize: '14px' }} />}
                                            unCheckedChildren={<RightOutlined style={{ color: 'blue', fontSize: '14px' }} />}
                                            defaultChecked={false}
                                            style={{
                                                border: '1px solid gray',
                                                boxShadow: '0px 0px 1px white',
                                                borderRadius: '7px',
                                                padding: '0px',
                                            }}
                                            onChange={(checked) => handleConfirmAccountsChange({ target: { checked } })}
                                        />
                                        <Typography.Text strong>Confirm # of Accounts is Correct</Typography.Text>
                                    </Space>
                                </Col>
                            </Row>
                        </>
                    )}

                    {/* Render Confirm # of accounts checkbox BEFORE table if secondaryAccounts is 0 */}
                    {secondaryAccounts === 0 && (
                        <Row gutter={24}>
                            <Col span={12} offset={4} style={{ marginTop: '20px', marginBottom: '70px' }}>
                                <Space direction="horizontal" size="middle">
                                    <Switch
                                        checkedChildren={<CheckOutlined style={{ color: 'white', fontSize: '14px' }} />}
                                        unCheckedChildren={<RightOutlined style={{ color: 'blue', fontSize: '14px' }} />}
                                        defaultChecked={false}
                                        style={{
                                            border: '1px solid gray',
                                            boxShadow: '0px 0px 1px white',
                                            borderRadius: '7px',
                                            padding: '0px',
                                        }}
                                        onChange={(checked) => handleConfirmAccountsChange({ target: { checked } })}
                                    />
                                    <Typography.Text
                                        style={{ fontSize: '16px', fontWeight: 'bold' }}>
                                        Confirm # of Accounts is Correct
                                    </Typography.Text>
                                </Space>
                            </Col>
                        </Row>
                    )}

                    {/* Error message */}
                    {errorMessage && (
                        <Row gutter={24}>
                            <Col span={16} offset={4}>
                                <Alert message={errorMessage} type="error" showIcon />
                            </Col>
                        </Row>
                    )}
                </>
            )}

            {/* Form Submit buttons (conditionally render Next button based on "Confirm # of accounts" checkbox) */}
            <Row gutter={24}>
                <Col span={16} offset={4}>
                    <Form.Item>
                        <Space>
                            {isConfirmAccountsChecked && isChecked && ( // Only show Next button when checkbox is checked and no validation errors
                                <Button type="primary" htmlType="submit">
                                    Next
                                </Button>
                            )}
                            <Button onClick={() => prev()}>
                                Previous
                            </Button>
                        </Space>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
}

export default Users;
