import React from 'react';
import { Col, Row, Form, Input, Button, Select, Space, Typography, message, theme } from 'antd';
import { CRMConnectionData } from '../AppState';

const CRMConnection = ({ prev, next, apiConnection }) => {
    const { token } = theme.useToken();
    const [messageApi, contextHolder] = message.useMessage();
    const [crm, setCrm] = React.useState('');

    React.useEffect(() => {
        CRMConnectionData.value['crm'].value !== '' && setCrm(CRMConnectionData.value['crm'].value);
    }, []);

    const onFinish = async (values) => {
        const key = 'updatable';
        messageApi.open({
            key,
            type: 'loading',
            content: 'Validating API key...'
        });

        apiConnection.init(values['crm'], { apikey_accesstoken: values['apikey'] })
        let response = await apiConnection.testConnection();

        if (response) {
            messageApi.open({
                key,
                type: 'success',
                content: 'Great! API key is valid!',
                duration: 1
            }).then(() => {
                for (const key in values) {
                    if (Object.hasOwnProperty.call(values, key)) {
                        CRMConnectionData.value[key].value = values[key];
                    }
                }
                next();
            });
        } else {
            messageApi.open({
                key,
                type: 'error',
                content: 'Invalid API Key. API Key Must be The Primary Follow up Boss Owner Account',
            });
        }

    }
    return (
        <Form
            layout="vertical"
            onFinish={onFinish}
        >
            {contextHolder}
            <Row gutter={24}>
                <Col span={16} offset={4}>
                    <Typography.Title level={3} style={{ textAlign: 'center', marginTop: '5px', marginBottom: '10px', color: token.colorLink }}>Your CRM & API Key</Typography.Title>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={16} offset={4}>
                    <Typography.Text style={{ textAlign: 'center', fontSize: '16px', fontWeight: 'bold', display: 'block' }}>This Integration Setup is for 1 CRM Account Only!                        
                    </Typography.Text>
                    <Typography.Text style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'italic', marginBottom: '20px', marginTop: '10px', display: 'block' }}>
                    Re-Submit This Form For Any Additional CRM's Separately.<br />Each Entry Must Be a Different CRM Account & New Unique API Key
                    </Typography.Text>
                    <br />
                </Col>
            </Row>
            <Row gutter={24}>

                <Col sm={{ span: 8, offset: 7 }} span={16} offset={4}>
                    <Form.Item 
                        label={
                            <span style={{ fontWeight: 'bold', fontSize: '16px', marginBottom: '0px', display: 'block' }}>Primary CRM
                            </span>
                        }
                        name="crm" rules={[{ required: true, message: 'Select Your CRM' }]} initialValue={CRMConnectionData.value['crm'].value}
                        >
                        <Select
                            style={{ width: '100%', textAlign: 'left', fontWeight: 'bold', marginBottom: '5px', }}
                            onChange={(value) => setCrm(value)}
                            options={[
                                { value: 'follow-up-boss', label: 'Follow up Boss' }
                                //{ value: 'sierra-interactive', label: 'Sierra Interactive'},
                                //{ value: 'chime', label: 'Chime/Lofty'},
                                //{ value: 'kvcore', label: 'KvCore'}
                            ]}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
    <Col sm={{ span: 10, offset: 7 }} span={16} offset={4}>
        <Form.Item 
            label={
                <span>
                    <span style={{ fontWeight: 'bold', fontSize: '16px', marginTop: '25px', marginBottom: '0px' }}>
                        Owner API Key
                    </span>&nbsp;&nbsp;
                    <Typography.Link
                style={{ fontStyle: 'normal', fontSize: '12px', fontWeight: 'bold', marginTop: '25px', marginBottom: '0px' }}
                target="_blank"
                href={apiConnection.getDocumentationUrls()[crm]}
            >
                Need Help finding API Key?
            </Typography.Link>
                </span>
            }
            name="apikey" 
            rules={[
                { 
                    required: true, 
                    message: (
                        <>
                            *OWNER API KEY ONLY REQUIRED* <br /> API Key Is Required to be from The Primary CRM Owners Account!
                            <br />
                            Integration Will Not Properly Operate Without Correct Key. Please Update!
                        </>
                    ) 
                }
            ]} 
            initialValue={CRMConnectionData.value['apikey'].value} 
            style={{ marginBottom: '15px' }}
        >
            <Input />
        </Form.Item>
        
        <Typography.Text
            style={{
                fontStyle: 'italic', 
                textAlign: 'left', 
                fontSize: 'small', 
                fontWeight: 'regular', 
                marginTop: '5px', 
                marginBottom: '70px', 
                display: 'block'
            }}
        >
            *REQUIRED* API Key is Required to be from Primary CRM Owner Account ONLY!
        </Typography.Text>
    </Col>
</Row>

            <Row gutter={24}>
                <Col sm={{ span: 8, offset: 7 }} span={16} offset={4}>
                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit" style={{ width: '100%', height: '32px', fontWeight: 'bold' }}>
                                Next
                            </Button>
                            <Button onClick={() => prev()}>
                                Previous
                            </Button>
                        </Space>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
}

export default CRMConnection;