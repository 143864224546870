import React, { useState } from 'react';
import { ConfigProvider, Steps, theme, Col, Row, Typography, Space, Flex, Layout, Switch } from 'antd';
import './App.css';
//import partnerLogo from './homebotPartnerV2.png';
import darkLogo from './homebotPartnerV2-dark.png';
import lightLogo from './homebotPartnerV3-light.png'; 
import ApiConnection from './api/apiConnection';
import ContactInfo from './steps/ContactInfo';
import CRMConnection from './steps/CRMConnection';
import Users from './steps/Users';
import Submit from './steps/Submit';

let apiConnection = new ApiConnection();

const App = () => {
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const [isDarkMode, setIsDarkMode] = useState(prefersDarkMode);

  const handleModeClick = () => {
    setIsDarkMode((previousValue) => {
      console.log("Toggling Dark Mode:", !previousValue); // Debugging log
      return !previousValue;
    });
  };

  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const steps = [
    {
      title: 'Contact Info',
      content: <ContactInfo prev={prev} next={next} />,
    },
    {
      title: 'CRM Connection',
      content: <CRMConnection prev={prev} next={next} apiConnection={apiConnection} />,
    },
    {
      title: 'Accounts',
      content: <Users prev={prev} next={next} apiConnection={apiConnection} />,
    },
    {
      title: 'Submit',
      content: <Submit prev={prev} next={next} />,
    }
  ];
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const partnerLogo = isDarkMode ? darkLogo : lightLogo;


  const contentStyle = {
    minHeight: 540,
    color: token.colorTextTertiary,
    borderRadius: token.borderRadiusLG,
    marginTop: 16,
  };
  return (
    <ConfigProvider theme={{algorithm: isDarkMode ? theme.darkAlgorithm : theme.defaultAlgorithm}}>
      <Layout.Content id="abc" style={{backgroundColor: isDarkMode ? '#161a1c' : ''}}>
        <Row justify='center'>
          <Col>
            <Space.Compact direction="vertical" align='center'>
              <Typography.Title level={2} style={{ textAlign: 'center', marginTop: '40px', fontWeight: 'normal', marginBottom: '30px' }}>Homebot CRM Integration Setup</Typography.Title>
              {/* Typography.Title level={5} type="secondary" style={{ margin: '0px 0px 20px 0px' }}>To continue, we need Your Contact & CRM Info</Typography.Title> */}
            </Space.Compact>
          </Col>
        </Row>
        <Row>
        <Col span={24}>
          <Row>
            {/* Adjust the logo column to be more responsive */}
            <Col xs={24} sm={12} md={6}>
              <Flex vertical justify={'center'} align={'center'} style={{ height: '80%', padding: '0 10px' }}> 
                <img
                  src={partnerLogo}  // Use the conditionally selected logo
                  style={{ width: '100%', maxWidth: 330, padding: 10, marginLeft: '50px', borderRadius: 10 }} 
                  alt="Partner Logo"
                />
              </Flex>
            </Col>
            <Col xs={24} sm={12} md={16} style={{ alignItems: 'center', justifyContent: 'center'}}>
              <Steps progressDot current={current} items={items} size="small" />
              <div style={contentStyle}>{steps[current].content}</div>
            </Col>
          </Row>
        </Col>
        </Row>
        <Row justify='center'style={{ marginTop: '75px' }}>
          <Col>
              <Space.Compact direction="vertical" align='center'>
                <Typography.Text type="secondary">Need help? contact support at <Typography.Link target="_blank" href="mailto:support@homebot.ai">support@homebot.ai</Typography.Link> <br />By continuing, you are accepting the <Typography.Link target="_blank" href="https://homebot.ai/terms-of-service">terms and conditions</Typography.Link> of the system and integration.  <Switch 
                  onClick={handleModeClick} 
                  checkedChildren="Dark" 
                  unCheckedChildren="Light" 
                  checked={isDarkMode} // Use "checked" instead of "defaultChecked"
                /></Typography.Text><br></br><br></br><br></br><br></br>
              </Space.Compact>
            </Col>
        </Row>
      </Layout.Content>
    </ConfigProvider>
  );
};
export default App;


